import React, { useContext, useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import SEO from "../components/seo"
import Footer from "../components/common/Footer"
import { CampaignContext } from "../context/CampaignContext"
import "./index.css"
import ChevronLeftIcon from "../components/common/icons/ChevronLeftIcon"
import PageLoader from "../components/common/loaders/PageLoader"
import CartContent from "../components/cart/CartContent"
import Navbar from "../components/common/Navbar"
import Alert from "../components/common/Alert"
import { useCart } from "../components/common/hooks/useCart"

const CartPage = ({ data }) => {
  const { campaign } = useContext(CampaignContext)
  const [showAlert, setShowAlert] = useState(false)
  const {
    cart,
    status: cartStatus,
    errorMessage,
    updateQuantity,
    updateCustomField,
    removeItem,
    handleCheckout,
  } = useCart()

  useEffect(() => {
    if (cartStatus === "ERROR") {
      setShowAlert(true)
    }
  }, [cartStatus, setShowAlert])

  const onQuantityChange = async (product, quantity) => {
    updateQuantity(product, quantity)
  }

  const onCustomFieldChange = async (product, customField) => {
    updateCustomField(product, customField)
  }

  const onRemoveItem = async (product) => {
    removeItem(product)
  }

  const onCheckout = async () => {
    const matchingCampaign = data.allSanityCampaign.edges.find(
      (campaignItem) => campaignItem.node.slug.current === campaign
    )

    await handleCheckout(matchingCampaign)
  }

  const renderCartState = () => {
    switch (cartStatus) {
      case "SUCCESS":
      case "LOADING_TOTAL":
      case "LOADING_CHECKOUT":
      case "ERROR":
        return (
          <CartContent
            cart={cart}
            status={cartStatus}
            onQuantityChange={onQuantityChange}
            onCustomFieldChange={onCustomFieldChange}
            onRemoveItem={onRemoveItem}
            onCheckout={onCheckout}
          />
        )
      case "EMPTY_CART":
        return (
          <div className="p-8">
            <h1 className="text-2xl font-thin">Your cart is empty</h1>
          </div>
        )
      default:
        return (
          <div className="flex justify-center py-16">
            <PageLoader />
          </div>
        )
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-white md:bg-gray-100">
      <SEO pageTitle="Cart" ogTitle="Cart" pathname="/cart" />
      {showAlert && (
        <Alert
          onClose={() => {
            setShowAlert(false)
          }}
          message={errorMessage}
          type="ERROR"
        />
      )}
      <Navbar />
      <main className="flex-grow">
        <div className="max-w-4xl mx-auto my-4">
          <button
            onClick={() => navigate(`/${campaign}`)}
            className="inline-flex items-center text-blue-600"
          >
            <ChevronLeftIcon /> Continue Shopping
          </button>
        </div>
        <div className="w-full max-w-4xl p-4 mx-auto mb-8 bg-white rounded-md">
          {renderCartState()}
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allSanityCampaign {
      edges {
        node {
          vendor {
            shippingTiers {
              quantity
              shippingPrice
              stripeReferenceId
              dimensions {
                height
                length
                weight
                width
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default CartPage
