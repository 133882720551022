import React from "react"

const QuantityField = ({ product, onQuantityChange }) => (
  <div>
    <label
      htmlFor="quantity"
      className="block text-sm font-medium leading-5 text-gray-700"
    >
      Quantity
    </label>
    <div className="relative mt-1 rounded-md shadow-sm">
      <input
        id="quantity"
        type="number"
        className="block w-20 form-input sm:text-sm sm:leading-5"
        onChange={(e) => onQuantityChange(product, e.target.value)}
        value={product.quantity}
      />
    </div>
  </div>
)

export default QuantityField
