import React from "react"
import "./inline-loader.css"

const Loader = () => {
  return (
    <div className="dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
