import React from "react"
import { formatPrice } from "../../utils/currency.util"
import PageLoader from "../common/loaders/PageLoader"

const CartTotal = ({ status, total }) => (
  <section className="grid grid-cols-1 md:grid-cols-3 ">
    <div />
    <div />
    <div className="flex items-center justify-between text-gray-900">
      Subtotal:
      {status === "LOADING_TOTAL" ? (
        <span className="ml-4 my-0.5">
          <PageLoader size={6} />
        </span>
      ) : (
        <span className="ml-4 text-xl font-bold text-indigo-500">
          {formatPrice(total)}
        </span>
      )}
    </div>
    <div />
    <div />
    <div className="flex items-center justify-between text-gray-900">
      Shipping:
      <span className="ml-4 text-sm italic text-gray-800">
        Calculated at checkout
      </span>
    </div>
  </section>
)

export default CartTotal
