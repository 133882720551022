import React from "react"
import GatsbyImage from "gatsby-image"
import { formatPrice } from "../../utils/currency.util"
import QuantityField from "./QuantityField"
import CustomField from "./CustomField"

const ProductLineItem = ({
  product,
  onQuantityChange,
  onCustomFieldChange,
  onRemoveItem,
  ...rest
}) => (
  <section className="grid grid-cols-1 my-8 md:grid-cols-2" {...rest}>
    <div className="flex items-center">
      <GatsbyImage
        fluid={product.image.asset.fluid}
        alt={product.title}
        className="w-16 h-16 rounded-lg shadow-lg md:w-20 md:h-20"
      />
      <h2 className="ml-4 text-lg font-semibold text-gray-700">
        {product.title}
      </h2>
    </div>
    <div className="flex items-center justify-between mt-2 md:mt-0">
      <div>
        <div className="flex">
          <QuantityField
            product={product}
            onQuantityChange={onQuantityChange}
          />
          <div className="ml-5">
            {product.options.map((option) => (
              <CustomField
                key={option.key}
                product={product}
                option={option}
                onChange={onCustomFieldChange}
              />
            ))}
          </div>
        </div>
        <div className="mt-2">
          <button
            className="text-sm text-red-700"
            onClick={() => onRemoveItem(product)}
          >
            Remove Item
          </button>
        </div>
      </div>
      <div className="mt-4 text-lg font-semibold text-gray-500">
        {formatPrice(product.quantity * product.price)}
      </div>
    </div>
  </section>
)
export default ProductLineItem
