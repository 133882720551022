import React from "react"
import Loader from "../common/loaders/InlineLoader"
import ProductLineItem from "./ProductLineItem"
import CartTotal from "./CartTotal"
import Button from "../common/Button"

const CartContent = ({
  cart,
  status,
  onCheckout,
  onQuantityChange,
  onCustomFieldChange,
  onRemoveItem,
}) => {
  const cartTotal = cart.items.reduce(
    (total, item) => (total += item.price * item.quantity),
    0
  )
  return (
    <>
      <h1 className="my-8 ml-4 text-3xl font-thin">Your Cart</h1>
      {cart.items.map((product) => (
        <ProductLineItem
          key={product.sku}
          product={product}
          onQuantityChange={onQuantityChange}
          onCustomFieldChange={onCustomFieldChange}
          onRemoveItem={onRemoveItem}
        />
      ))}
      <hr className="my-4" />
      <CartTotal status={status} total={cartTotal} />
      <section className="grid grid-cols-1 mt-8 md:grid-cols-3">
        <div />
        <div />
        <Button
          className="flex items-center justify-center duration-700 h-11"
          onClick={onCheckout}
        >
          {status === "LOADING_CHECKOUT" ? <Loader /> : "Proceed to Checkout"}
        </Button>
      </section>
    </>
  )
}

export default CartContent
