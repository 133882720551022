import React from "react"
import classnames from "classnames"

const Button = ({ color = "indigo", children, className, ...rest }) => {
  const buttonClassName = classnames(
    `w-full px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-${color}-600 hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} active:bg-${color}-700 transition ease-in-out duration-150`,
    className
  )
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <button {...rest} className={buttonClassName}>
        {children}
      </button>
    </span>
  )
}

export default Button
