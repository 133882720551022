import React from "react"

const CustomField = ({ option, product, onChange, ...rest }) => {
  const onSelect = (e) => {
    const customFieldData = {
      ...option,
      selectedValue: e.target.value,
    }
    onChange(product, customFieldData)
  }

  return (
    <div {...rest}>
      <label
        htmlFor={`${option.key}-field`}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {option.label}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <select
          id={`${option.key}-field`}
          className="block w-36 form-select sm:text-sm sm:leading-5"
          onBlur={onSelect}
          defaultValue={option.selectedValue}
        >
          {option.possibleValues.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default CustomField
